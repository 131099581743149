.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  /* background: linear-gradient(270deg, #ec4899, #3b82f6); Initial colors */
  background-size: 400% 400%; /* Increase the size for smooth transition */
  animation: gradient-animation 5s ease infinite; /* Adjust duration and ease as needed */
}

/* .glow-overlay {
  --glow-size: 25rem;
  position: absolute;
  inset: 0;
  pointer-events: none;
  user-select: none;
  opacity: var(--glow-opacity, 0);
  mask: radial-gradient(
    var(--glow-size) var(--glow-size) at var(--glow-x) var(--glow-y),
    var(--glow-color) 1%,
    transparent 50%
  );
  transition: 400ms mask ease;
  will-change: mask;
} */
 


.blog-description ul {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.blog-description ol {
  padding-left: 1.5rem; /* Optional: Adjust for ordered lists */
}

.blog-description li {
  padding-left: 1.5rem; /* Indentation for the list items */
}

/* For nested lists (to increase indentation) */
.blog-description li ul, .blog-description li ol {
  padding-left: 2rem; /* Increase indentation for nested lists */
}